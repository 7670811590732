import * as React from 'react';

interface Props {
  title: string;
  subText?: string;
  classes?: string;
}

const TitleContainer = ({ title, subText, ...otherProps }: Props) => (
  <div className="flex h-16 flex-col items-center justify-center bg-blue sm:h-20 md:h-36">
    <h1
      className="flex h-full w-full max-w-base items-center overflow-hidden px-1 font-hvBold text-2xl tracking-tight text-white sm:text-4xl md:text-7xl"
      {...otherProps}
    >
      {title}
    </h1>
    {subText && (
      <h3 className="flex w-full max-w-base items-center px-1 font-hvMedium text-sm text-white sm:text-lg md:text-3xl">
        {subText}
      </h3>
    )}
  </div>
);

export default TitleContainer;
